import React from 'react'
import LetsTalkForm from './components/form'
import BreadCrum from '../../Components/utility/BreadCrum'

export default function LetsTalk() {
    document.title = 'Mirax AR - Let\'s talks'

    return (
        <div className='brand-container bg-[#0D0F1F] py-10 pt-[150px] text-white'>
            {/* breadcrum */}
            <BreadCrum>
                <p className='font-bold text-xl'>Let's Talk</p>
            </BreadCrum>
            {/* breadcrum */}

            <div className='bg-[#181E25] py-10 px-5 md:px-10 lg:px-10 xl:px-10 rounded-xl mt-4 w-full'>
                <LetsTalkForm />
            </div>
        </div>

    )
}
