import React from 'react'
import PropTypes from "prop-types";
import { classNames } from '../../Utility/Classnames';


function Button({ className, children, rounded, variant, ...props }) {
    return (
        <button
        className={classNames(
            "px-[20px] py-[13px] cursor-pointer font-sato-medium select-none capitalize [outline:none] shadow-md active:opacity-75 disabled:cursor-not-allowed disabled:bg-neutral-300 disabled:text-neutral-100 disabled:shadow-inner",
            rounded ? "rounded-full" : "",
            variant === "primary"
              ? "bg-primary text-white "
              : "",
              variant === "secondary" ?
              "bg-white text-primary" :
              "",
           
            className
          )}
         {...props}>
            {children}
        </button>
    )
} 

export default Button


Button.propTypes = {
    className: PropTypes.string,
    children: PropTypes.node.isRequired,
    rounded: PropTypes.bool.isRequired,
    variant: PropTypes.oneOf([
      "primary",
      "secondary",
      "brand_primary",
      "form_primary",
      "white_button",
    ]),
  };