import React, { useContext } from 'react'
import Button from '../../Components/Formcomponents/Button'
// import { useNavigate } from 'react-router'
import { Link } from 'react-router-dom'
import { Appcontext } from '../../Contextapi/AppContext'

function Footer() {

    // const navigate = useNavigate()
    const {setcontactModalstate} = useContext(Appcontext)

    const FooterContent = [
        {
            id: 1,
            title: 'Quick Links',
            list: [
                {
                    id: 1,
                    name: 'website',
                    path: '/website-integration',
                },
                {
                    id: 2,
                    name: '',
                    // instore
                    path: '',
                },
                {
                    id: 2,
                    name: '',
                    // application
                    path: '',
                },
                
            ]
        },
        {
            id: 2,
            title: 'Useful Links',
            list: [
                {
                    id: 1,
                    name: 'privacy policy',
                    path: '/privacy-policy',
                },
                {
                    id: 2,
                    name: 'terms & conditions',
                    path: '/terms-&-condition',
                },
                {
                    id: 3,
                    name: '',
                    // about us
                    path: '',
                },
            ]
        },

    ]

    return (
        <>
            <div className='bg-[#181E25] relative z-10'>
                <div className=' gap-5 brand-container flex flex-col md:flex-row lg:flex-row xl:flex-row justify-between items-start md:items-center  pt-20 '>
                    <div className='flex flex-col gap-5'>
                        <img className='aspect-video w-28 object-contain ' src="/assets/svg/logo.svg" alt="" />
                        <ul className='flex flex-col gap-3'>
                            <li className='flex gap-2 items-center'>
                                <img className='w-8 aspect-square object-contain' src="/assets/svg/map.svg" alt="" />
                                <address className='text-white'>
                                Chennai, Tamilnadu, India
                                </address>
                            </li>
                            <li className='flex gap-2 items-center'>
                                <img className='w-8 aspect-square object-contain' src="/assets/svg/call.svg" alt="" />
                                <p className='text-white '>099528 44699</p>
                            </li>
                            <li className='flex gap-2 items-center'>
                                <img className='w-8 aspect-square object-contain' src="/assets/svg/mail.svg" alt="" />
                                <p className='text-white '>info@hashlays.com</p>
                            </li>
                        </ul>
                    </div>
                    {/* <div className='flex  w-full justify-around'> */}
                    {FooterContent.map((item, index) =>
                        <>
                            <div className='flex items-start  md:pt-12 flex-col' key={index}>
                                <p className='pb-5  text-white'>{item.title}</p>
                                <ul className='flex flex-col  gap-4 w-full'>
                                    {item.list.map((list, index) =>
                                        <li key={index} >
                                            <Link to={list.path} className='text-[#AAAAAA] capitalize cursor-pointer active:opacity-50 select-none'>{list.name}</Link>
                                        </li>
                                    )}
                                </ul>
                            </div>
                        </>

                    )}
                    {/* </div> */}
                    <div className=''>
                    <Button onClick={()=> setcontactModalstate(true)} className={'uppercase ' } variant={'primary'} rounded={true}>get in touch</Button>
                    </div>

                </div>
                <div className='brand-container pt-5'>

                    <div className='w-full h-[1px] bg-gradient-to-r from-[#000000] via-white to-[#000000] '/>
                    <p className='text-white/60 py-10 text-center'>Copyright 2023 All Rights Reserved</p>

                </div>


            </div>
        </>
    )
}

export default Footer