import React, { useContext } from 'react'
import Button from '../../../Components/Formcomponents/Button'
import { Appcontext } from '../../../Contextapi/AppContext'

function Tryforfree() {
    const {setcontactModalstate} = useContext(Appcontext)
    return (
        <>
            <div className='brand-container relative  bg-[#0D0F1F] py-10'>
                <video className='absoute inset-0 h-[500px] object-cover w-full' src="/assets/video/cmp-bg-video.mp4" muted={true} autoPlay={true} loop={true} />
                <div className='bg-black/20 backdrop-blur-md absolute inset-0 gap-10 flex flex-col justify-center items-center'>
                    <p className='uppercase text-3xl md:text-5xl lg:text-5xl xl:text-5xl font-bold text-white'>try <span className='text-[#38C558]'>for free</span> </p>
                    <p className='text-3xl md:text-5xl lg:text-5xl xl:text-5xl font-bold text-center text-white/40'>Enjoy free <span className='text-white'>Augmented Reality</span>  for first customers</p>
                    <Button variant={'primary'} rounded={true} className={'uppercase font-bold text-xl'}>get in touch</Button>
                </div>
            </div>
            <div className='brand-container relative  bg-[#0D0F1F] py-10  md:px-10 lg-px-20 xl:px-32'>
                <div className='p-5 bg-[#181E25] rounded-lg flex flex-col md:flex-row lg:flex-row xl:flex-row justify-between gap-5 items-center'>
                    <h1 className='text-white font-bold text-4xl bg-gradient-to-r bg-clip-text text-white/0 from-white to-[#47A2EB] to-70%'>Start Your Augmented <br />Reality journey today.</h1>
                    <div className='w-full md:w-1/2 lg:w-1/2 xl:w-1/2 flex flex-col gap-5 items-start'>
                        <p className='text-lg text-[#ABABAB]'>Prepare to embark on your augmented reality adventure; reach out to our sales team for further information.</p>
                        <Button onClick={()=> setcontactModalstate(true)} variant={'primary'} rounded={true}>Contact us</Button>
                    </div>

                </div>
            </div>
        </>
    )
}

export default Tryforfree