import { useState } from "react";
import { NotificationService } from "../Service/NotificationService";
import ApiService from "../Services/ApiService";


function useFetchData() {
  const [data, setData] = useState(null); 
  const [loading, setLoading] = useState(false); 
  const [error, setError] = useState(null); 


  //Refetch used For Onclick or Triger Event Call Function
  const makeRequest = async (url, method, dataGive) => {
    try {
      let Responsedata;
      setLoading(true)
      // const Responsedata = await Api.onlineconnect(url, method, dataGive)
      switch (method) {
        case 'get':
          Responsedata = await ApiService.get(url, {})
          break;

        case 'post':
          Responsedata = await ApiService.post(url, dataGive)
          break;

        default:
          Responsedata = { data: {}, message: "Invalid AXIOS Method", status: 'true' }
          break;
      }
      setLoading(false)

      if (Responsedata) {
        setData(Responsedata)
        return Responsedata

      } else {
        
        return [];

      }

    } catch (err) {
      NotificationService(err.response ? err.response.data.error.message : err.message, 'error')
      setLoading(false)
      setError(err)
      throw err

    }

  };

  return { data, loading, error, makeRequest };
}

export default useFetchData;
