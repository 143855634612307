import React, { useEffect } from 'react'

// import Navbar from '../Layout/Navbar'

import HeroSection from './Components/HeroSection'
// import Footer from '../Layout/Footer'
// import Whatwedo from './Components/Whatwedo'
import { ScrolltoTop } from '../../Utility'
import Whychooseus from './Components/Whychooseus'
import Tryforfree from './Components/Tryforfree'
import Testimonial from './Components/Testimonial'
import HowitWorks from './Components/HowitWorks'
import Faq from './Components/Faq'
import Modal from './Components/Modal'


function Homepage() {

    // const [ModalState, setModalState] = useState(false)

    document.title = 'Hashlays - Homepage'

    useEffect(() => {
        ScrolltoTop()
    }, [])



    return (
        <>
            <HeroSection />
            <Whychooseus />
            <HowitWorks />
            {/* <Whatwedo /> */}
            <Tryforfree />
            <Testimonial />
            <Faq />
            <Modal />
        </>
    )
}

export default Homepage


