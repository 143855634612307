import React, { useState } from 'react'
// import { ARButton } from './ARButton';
import * as THREE  from 'three';
// import  * as THREE from "@react-three/xr"
// import * as THREE from '@react-three/drei';


export default  function Celbrate() {
    const renderer= new THREE.WebGLRenderer({antialias:true,alpha:true});
    const [iframState, setiframeState] = useState(false);

    if ("xr" in window.navigator) {
        /* WebXR can be used! */

        console.log('WebXR can be used!');
      } else {
        /* WebXR isn't available */
        console.log('WebXR can be used!');
      }

    
        // window.navigator.permissions.query({name: 'xr'});

      window.navigator.xr.requestSession("immersive-ar")
                .then(async (xrSession) => {
                    xrSession.xr.setReferenceSpaceType( 'local' );

				await renderer.xr.setSession( xrSession );
                renderer.xr.enabled = true;
                    xrSession.addEventListener('end', '');
        // Do necessary session setup here.
        // Begin the session’s animation loop.
        // xrSession.requestAnimationFrame('');
        }).catch(function(error) {
        // "immersive-vr" sessions are not supported
        console.warn("'immersive-ar' isn't supported, or an error occurred activating Ar!");
        });

   
    return (
        <div className='fixed inset-0 bg-[#0D0F1F] z-[99] flex justify-center items-center '>
            <div className='flex flex-col items-center'>
                <img width={250} height={250} src="/assets/leo/leo.png" alt="" className='animate-halfRotate' />
                      <button onClick={() => {setiframeState(true)}} className='px-5 py-3 bg-primary text-white rounded-xl capitalize active:opacity-50 transition-all duration-300 font-earth-orbit-bold'>
                    Click
                </button>
            </div>

            <IframeSection State={iframState} Handler={setiframeState} />
        </div>
    )
}


const IframeSection = ({ State, Handler }) => {
    return (
        <div className={`${State ? 'fixed' : 'hidden'} inset-0 m-5 z-[999]`}>
            <div className='bg-white relative rounded-lg overflow-hidden h-full w-full'>
                <div onClick={() => Handler(!State)} className='text-primary absolute top-0 right-0 active:scale-95 transition-all duration-200 cursor-pointer'>
                    <Close />
                </div>
                <iframe id="" title='mirax' src={State? 'https://benevolent-duckanoo-3db90c.netlify.app/' :''} className='w-full h-full' frameborder="0">
                </iframe>
            </div>

        </div>
    )
}


function Close(props) {
    return <svg stroke="currentColor" fill="currentColor" strokeWidth={0} viewBox="0 0 1024 1024" height="2.4rem" width="2.4rem" {...props}><path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm165.4 618.2l-66-.3L512 563.4l-99.3 118.4-66.1.3c-4.4 0-8-3.5-8-8 0-1.9.7-3.7 1.9-5.2l130.1-155L340.5 359a8.32 8.32 0 0 1-1.9-5.2c0-4.4 3.6-8 8-8l66.1.3L512 464.6l99.3-118.4 66-.3c4.4 0 8 3.5 8 8 0 1.9-.7 3.7-1.9 5.2L553.5 514l130 155c1.2 1.5 1.9 3.3 1.9 5.2 0 4.4-3.6 8-8 8z" /></svg>;
}
