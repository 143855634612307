import React from 'react'
import { ScrolltoTop } from '../../Utility'
import BreadCrum from '../../Components/utility/BreadCrum';

function TermsandConditions() {
    ScrolltoTop();
    document.title = 'Mirax AR - Terms & Conditiions'

    return (
      <div className="bg-[#0D0F1F] pt-[150px] relative ">
        <div className="fixed inset-0 flex justify-center">
          <div className="w-[500px] h-[200px] bg-[#3FB2FF]  rounded-full opacity-20 filter blur-[100px] z-0"></div>
        </div>
        <div className="brand-container flex flex-col gap-5 pt-5 pb-20 relative z-10">
          {/* breadcrum */}
          <BreadCrum>
            <p className="capitalize font-bold">Tersm & Condition</p>
          </BreadCrum>
          <h3 className="text-3xl text-white font-earth-orbit-bold">
            Terms & <span className="text-[#C57538]">Conditions </span>
          </h3>
          <Paragraph>Welcome to MIRAX AI!</Paragraph>
          <Paragraph>
            These terms and conditions outline the rules and regulations for the
            use of Hashlays Global Tech Private Limited's Website, located at
            https://www.hashlays.com.
          </Paragraph>
          <Paragraph>
            By accessing this website we assume you accept these terms and
            conditions. Do not continue to use MIRAX AI if you do not agree to
            take all of the terms and conditions stated on this page.
          </Paragraph>
          <Paragraph>
            The following terminology applies to these Terms and Conditions,
            Privacy Statement and Disclaimer Notice and all Agreements:
            "Client", "You" and "Your" refers to you, the person log on this
            website and compliant to the Company's terms and conditions. "The
            Company", "Ourselves", "We", "Our" and "Us", refers to our Company.
            "Party", "Parties", or "Us", refers to both the Client and
            ourselves. All terms refer to the offer, acceptance and
            consideration of payment necessary to undertake the process of our
            assistance to the Client in the most appropriate manner for the
            express purpose of meeting the Client's needs in respect of
            provision of the Company's stated services, in accordance with and
            subject to, prevailing law of in. Any use of the above terminology
            or other words in the singular, plural, capitalization and/or he/she
            or they, are taken as interchangeable and therefore as referring to
            same.
          </Paragraph>
          <Subtitle>Cookies</Subtitle>
          <Paragraph>
            We employ the use of cookies. By accessing MIRAX AI, you agreed to
            use cookies in agreement with the Hashlays Global Tech Private
            Limited's Privacy Policy.
          </Paragraph>
          <Paragraph>
            Most interactive websites use cookies to let us retrieve the user's
            details for each visit. Cookies are used by our website to enable
            the functionality of certain areas to make it easier for people
            visiting our website. Some of our affiliate/advertising partners may
            also use cookies.
          </Paragraph>
          <Subtitle>License</Subtitle>
          <Paragraph>
            Unless otherwise stated, Hashlays Global Tech Private Limited and/or
            its licensors own the intellectual property rights for all material
            on MIRAX AI. All intellectual property rights are reserved. You may
            access this from MIRAX AI for your own personal use subjected to
            restrictions set in these terms and conditions.
          </Paragraph>
          <Paragraph>You must not:</Paragraph>
          <ul className="ml-10 flex flex-col gap-5 list-disc text-white">
            <li>
              <Paragraph>Republish material from MIRAX AI</Paragraph>
            </li>
            <li>
              <Paragraph>
                Sell, rent or sub-license material from MIRAX AI
              </Paragraph>{" "}
            </li>
            <li>
              <Paragraph>
                Reproduce, duplicate or copy material from MIRAX AI
              </Paragraph>
            </li>
            <li>
              <Paragraph>Redistribute content from MIRAX AI</Paragraph>
            </li>
          </ul>
          <Paragraph>
            This Agreement shall begin on the date hereof. Our Terms and
            Conditions were created with the help of the Free Terms and
            Conditions Generator.
          </Paragraph>
          <Paragraph>
            Parts of this website offer an opportunity for users to post and
            exchange opinions and information in certain areas of the website.
            Hashlays Global Tech Private Limited does not filter, edit, publish
            or review Comments prior to their presence on the website. Comments
            do not reflect the views and opinions of Hashlays Global Tech
            Private Limited,its agents and/or affiliates. Comments reflect the
            views and opinions of the person who post their views and opinions.
            To the extent permitted by applicable laws, Hashlays Global Tech
            Private Limited shall not be liable for the Comments or for any
            liability, damages or expenses caused and/or suffered as a result of
            any use of and/or posting of and/or appearance of the Comments on
            this website.
          </Paragraph>
          <Paragraph>
            Hashlays Global Tech Private Limited reserves the right to monitor
            all Comments and to remove any Comments which can be considered
            inappropriate, offensive or causes breach of these Terms and
            Conditions.
          </Paragraph>
          <Paragraph>You warrant and represent that:</Paragraph>
          <ul className="ml-10 flex flex-col gap-5 list-disc text-white">
            <li>
              <Paragraph>
                You are entitled to post the Comments on our website and have
                all necessary licenses and consents to do so;
              </Paragraph>
            </li>
            <li>
              <Paragraph>
                The Comments do not invade any intellectual property right,
                including without limitation copyright, patent or trademark of
                any third party;
              </Paragraph>{" "}
            </li>
            <li>
              <Paragraph>
                The Comments do not contain any defamatory, libelous, offensive,
                indecent or otherwise unlawful material which is an invasion of
                privacy
              </Paragraph>
            </li>
            <li>
              <Paragraph>
                The Comments will not be used to solicit or promote business or
                custom or present commercial activities or unlawful activity.
              </Paragraph>
            </li>
          </ul>
          <Paragraph>
            You hereby grant Hashlays Global Tech Private Limited a
            non-exclusive license to use, reproduce, edit and authorize others
            to use, reproduce and edit any of your Comments in any and all
            forms, formats or media.
          </Paragraph>
          <Subtitle>Hyperlinking to our Content</Subtitle>
          <Paragraph>
            The following organizations may link to our Website without prior
            written approval:
          </Paragraph>
          <ul className="ml-10 flex flex-col gap-5 list-disc text-white">
            <li>
              <Paragraph>Government agencies;</Paragraph>
            </li>
            <li>
              <Paragraph>Search engines;</Paragraph>{" "}
            </li>
            <li>
              <Paragraph>News organizations;</Paragraph>
            </li>
            <li>
              <Paragraph>
                Online directory distributors may link to our Website in the
                same manner as they hyperlink to the Websites of other listed
                businesses; and
              </Paragraph>
            </li>
            <li>
              <Paragraph>
                System wide Accredited Businesses except soliciting non-profit
                organizations, charity shopping malls, and charity fundraising
                groups which may not hyperlink to our Web site
              </Paragraph>
            </li>
          </ul>
          <Paragraph>
            These organizations may link to our home page, to publications or to
            other Website information so long as the link: (a) is not in any way
            deceptive; (b) does not falsely imply sponsorship, endorsement or
            approval of the linking party and its products and/or services; and
            (c) fits within the context of the linking party's site.
          </Paragraph>
          <Paragraph>
            We may consider and approve other link requests from the following
            types of organizations:
          </Paragraph>
          <ul className="ml-10 flex flex-col gap-5 list-disc text-white">
            <li>
              <Paragraph>
                commonly-known consumer and/or business information sources;
              </Paragraph>
            </li>
            <li>
              <Paragraph>dot.com community sites;</Paragraph>{" "}
            </li>
            <li>
              <Paragraph>
                associations or other groups representing charities;
              </Paragraph>
            </li>
            <li>
              <Paragraph>online directory distributors;</Paragraph>
            </li>
            <li>
              <Paragraph>internet portals;</Paragraph>
            </li>
            <li>
              <Paragraph>accounting, law and consulting firms; and</Paragraph>
            </li>
            <li>
              <Paragraph>
                educational institutions and trade associations.
              </Paragraph>
            </li>
          </ul>
          <Paragraph>
            We will approve link requests from these organizations if we decide
            that: (a) the link would not make us look unfavorably to ourselves
            or to our accredited businesses; (b) the organization does not have
            any negative records with us; (c) the benefit to us from the
            visibility of the hyperlink compensates the absence of Hashlays
            Global Tech Private Limited; and (d) the link is in the context of
            general resource information.
          </Paragraph>
          <Paragraph>
            These organizations may link to our home page so long as the link:
            (a) is not in any way deceptive; (b) does not falsely imply
            sponsorship, endorsement or approval of the linking party and its
            products or services; and (c) fits within the context of the linking
            party's site.
          </Paragraph>
          <Paragraph>
            If you are one of the organizations listed in paragraph 2 above and
            are interested in linking to our website, you must inform us by
            sending an e-mail to Hashlays Global Tech Private Limited. Please
            include your name, your organization name, contact information as
            well as the URL of your site, a list of any URLs from which you
            intend to link to our Website, and a list of the URLs on our site to
            which you would like to link. Wait 2-3 weeks for a response.
          </Paragraph>
          <Paragraph>
            Approved organizations may hyperlink to our Website as follows:
          </Paragraph>
          <ul className="ml-10 flex flex-col gap-5 list-disc text-white">
            <li>
              <Paragraph>By use of our corporate name; or</Paragraph>
            </li>
            <li>
              <Paragraph>
                By use of the uniform resource locator being linked to; or
              </Paragraph>
            </li>
            <li>
              <Paragraph>
                By use of any other description of our Website being linked to
                that makes sense within the context and format of content on the
                linking party's site.
              </Paragraph>
            </li>
          </ul>
          <Paragraph>
            No use of Hashlays Global Tech Private Limited's logo or other
            artwork will be allowed for linking absent a trademark license
            agreement.
          </Paragraph>
          <Subtitle>iFrames</Subtitle>
          <Paragraph>
            Without prior approval and written permission, you may not create
            frames around our Webpages that alter in any way the visual
            presentation or appearance of our Website.
          </Paragraph>
          <Subtitle>Content Liability</Subtitle>
          <Paragraph>
            We shall not be hold responsible for any content that appears on
            your Website. You agree to protect and defend us against all claims
            that is rising on your Website. No link(s) should appear on any
            Website that may be interpreted as libelous, obscene or criminal, or
            which infringes, otherwise violates, or advocates the infringement
            or other violation of, any third party rights.
          </Paragraph>
          <Subtitle>Reservation of Rights</Subtitle>
          <Paragraph>
            We reserve the right to request that you remove all links or any
            particular link to our Website. You approve to immediately remove
            all links to our Website upon request. We also reserve the right to
            amen these terms and conditions and it's linking policy at any time.
            By continuously linking to our Website, you agree to be bound to and
            follow these linking terms and conditions.
          </Paragraph>
          <Subtitle>Removal of links from our website</Subtitle>
          <Paragraph>
            If you find any link on our Website that is offensive for any
            reason, you are free to contact and inform us any moment. We will
            consider requests to remove links but we are not obligated to or so
            or to respond to you directly.
          </Paragraph>
          <Paragraph>
            We do not ensure that the information on this website is correct, we
            do not warrant its completeness or accuracy; nor do we promise to
            ensure that the website remains available or that the material on
            the website is kept up to date.
          </Paragraph>
          <Subtitle>Disclaimer</Subtitle>
          <Paragraph>
            To the maximum extent permitted by applicable law, we exclude all
            representations, warranties and conditions relating to our website
            and the use of this website. Nothing in this disclaimer will:
          </Paragraph>

          <ul className="ml-10 flex flex-col gap-5 list-disc text-white">
            <li>
              <Paragraph>
                limit or exclude our or your liability for death or personal
                injury;
              </Paragraph>
            </li>
            <li>
              <Paragraph>
                limit or exclude our or your liability for fraud or fraudulent
                misrepresentation;
              </Paragraph>
            </li>
            <li>
              <Paragraph>
                limit any of our or your liabilities in any way that is not
                permitted under applicable law; or
              </Paragraph>
            </li>
            <li>
              <Paragraph>
                exclude any of our or your liabilities that may not be excluded
                under applicable law.
              </Paragraph>
            </li>
          </ul>
          <Paragraph>
            The limitations and prohibitions of liability set in this Section
            and elsewhere in this disclaimer: (a) are subject to the preceding
            paragraph; and (b) govern all liabilities arising under the
            disclaimer, including liabilities arising in contract, in tort and
            for breach of statutory duty.
          </Paragraph>
          <Paragraph>
            As long as the website and the information and services on the
            website are provided free of charge, we will not be liable for any
            loss or damage of any nature.
          </Paragraph>
          <Paragraph>Generated using Terms and Conditions Generator</Paragraph>
        </div>
      </div>
    );
}

export default TermsandConditions



/**
 * Renders a paragraph of text with a specific styling.
 * 
 * @param {Object} props - The properties passed to the component.
 * @param {string} props.children - The text content of the paragraph.
 * 
 * @returns {JSX.Element} A <p> element with the specified styling and the provided text content.
 */
const Paragraph = ({ children }) => {
    return (
        <p className='text-[#6C6D74] leading-[30px]'>
            {children}
        </p>
    )
}
const Subtitle = ({ children }) => {
  return <h4 className="text-white text-xl">{children}</h4>;
};
// const Title = ({ children }) => {
//   return <h1 className="text-white text-3xl">{children}</h1>;
// };