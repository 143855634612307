import React, { useState } from 'react'
import { faq_data } from '../../../Utility/DynamicData';

function Faq() {
    const [active, setActive] = useState(0)
    const openHandler = (inde)=>{
        if(active === inde){
            setActive(-1);
        }else{
            setActive(inde)
        }

    }
    return (
        <div className=' brand-container bg-[#0D0F1F] py-20'>
            <div className='flex flex-col gap-5 justify-center py-5'>
                <h1 className='text-white uppercase text-center text-4xl font-earth-orbit-regular'>FAQ <span className='text-[#38C558]'>'S</span> </h1>
            </div>

            <div className='flex flex-col gap-5'>
                {faq_data.map((item,inde)=>
                <FaqComponent key={inde} active={active} handler={openHandler} data={item} uniq={inde}   />
                )}
            </div>

        </div>
    )
}

export default Faq


const FaqComponent = ({active, handler, data, uniq}) => {
    return (
        <div onClick={()=>{handler(uniq)}} className='p-5 bg-[#181E25] w-full flex flex-col gap-5 cursor-pointer rounded-xl overflow-hidden '>
            <div className='flex justify-between'>
                <h4 className='text-white text-xl'>{data.title}</h4>
                <img src="/assets/svg/plus.svg" className={`${uniq === active ? 'rotate-45':''} transition-all duration-500  `} alt="" />
            </div>
            <p  className={`text-[#898989] ${uniq ===  active ? 'h-[100px]':'h-0 invisible'}  transition-all duration-300 `}>{data.content}</p>
        </div>
    )
}