import React, { useContext, useEffect, useRef, useState } from 'react'
import Button from '../../../Components/Formcomponents/Button'
// import required modules
import { EffectCoverflow, Autoplay, Navigation } from 'swiper/modules';



// config:  plan depends on server configurations   

import { Swiper, SwiperSlide, } from 'swiper/react';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Appcontext } from '../../../Contextapi/AppContext';
import LoadingComponent from '../LoadingComponent';
// import ArComponents from '../../../Components/ARComponent';
// var iframe;
var lat=0.0;
var lang=0.0;
function HeroSection() {


    // const [ModalState, setModalState] = useState(false);
    // const [ModalType, setModalType] = useState('')
    const [iframstate, setiframestate] = useState(false)
    const {setarmodelState, ariframestate, setariframestate, arcomponent} = useContext(Appcontext);
    



    const TryDemoHandling = () => {
        setarmodelState(true)
    }
    useEffect(()=>{
        const getLocation = () => {
            if (navigator.geolocation) {
              navigator.geolocation.getCurrentPosition(
                position => {
                    // console.log("location accepted")
                  lat = position.coords.latitude
                  lang = position.coords.longitude
                  // console.log("appdate", appData)
                //   setLocation(true)
                },
                error => {
                  console.log(error.message);
                }
              );
            } else {
              console.log('Geolocation is not supported by your browser.');
            }
          };
          getLocation();
    })

    useEffect(() => {
        window.addEventListener("message", function (event) {
            if (event.data === "closeIframe") {
                setariframestate(false)
            }
        });
    
        // 
  
        if (iframstate) {
            navigator.mediaDevices.getUserMedia({ video: true })
                .then(function (stream) {
                    // Display the iframe with camera access

                    var iframe = document.getElementById("mirax-ar-iframe");
                    iframe.setAttribute('allow','microphone; camera'); 
                    // Attach the camera stream to an HTML video element inside the iframe, if needed
                    // console.log(stream)
                })
                .catch(function (error) {
                    console.error('Camera access denied:', error);
                });
        }


    },[iframstate, setariframestate])





    const ImageData = [
        {
            id: 1,
            title: '',
            image: 'https://images.unsplash.com/photo-1665594915243-970a1e128ba7?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=687&q=80',
        },
        {
            id: 2,
            title: '',
            image: 'https://images.unsplash.com/photo-1587573089283-f14c65841f75?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=930&q=80',
        },
        {
            id: 3,
            title: '',
            image: 'https://images.unsplash.com/photo-1529094344530-42a0d6a49e8c?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=870&q=80',
        },
        {
            id: 4,
            title: '',
            image: 'https://images.unsplash.com/photo-1581093206409-01076de81a1b?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=687&q=80',
        },
        {
            id: 5,
            title: '',
            image: 'https://images.unsplash.com/photo-1581092795360-fd1ca04f0952?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=687&q=80',
        },
        {
            id: 3,
            title: '',
            image: 'https://images.unsplash.com/photo-1529094344530-42a0d6a49e8c?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=870&q=80',
        },
        {
            id: 4,
            title: '',
            image: 'https://images.unsplash.com/photo-1581093206409-01076de81a1b?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=687&q=80',
        },
        {
            id: 5,
            title: '',
            image: 'https://images.unsplash.com/photo-1581092795360-fd1ca04f0952?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=687&q=80',
        },
    ]

    return (
        <>
            <div className='brand-container bg-[#0D0F1F]'>
                <div className='h-[1100px] lg:h-screen xl:h-screen pt-[150px] relative'>
                    <div className='animate-movingRight absolute translate-x-full top-3 left-3 w-[50%] md:w-[40%]  lg:w-[35%] xl:w-[35%] z-0 h-auto rounded-full aspect-square opacity-50 filter blur-[100px] bg-gradient-to-b from-[#3FE8FF] to-[#52FF00]'></div>
                    <div className='animate-movingLeft absolute bottom-3 right-3 w-[50%] md:w-[40%]  lg:w-[35%] xl:w-[35%] z-0 h-auto rounded-full aspect-square opacity-50 filter blur-3xl bg-gradient-to-b from-[#FF613F] to-[#EB00FF]'></div>
                    <p className='text-white/10 opacity-30 delay-300 font-bold absolute bottom-20 animate-movingRight text-[10rem] whitespace-nowrap z-0'>JEWELLERY COSMETICS FURNITURE</p>
                    <div className='absolute inset-0 flex flex-col justify-center '>

                        <div className='w-full mb-20 flex flex-col md:flex-row lg:flex-row xl:flex-row justify-center items-center gap-5'>
                            <p className='text-white  text-4xl text-center font-bold'>Transforming your business with</p>
                            <p className='text-4xl text-center font-bold bg-clip-text bg-gradient-to-r from-[#FFFFFF] to-[#3D9EEB] to-80% text-black/0 '>Cutting-Edge AR Solutions</p>
                        </div>
                        <div className='flex flex-col lg:flex-row xl:flex-row relative gap-8'>

                            <div className='w-full  lg:w-1/2 xl:w-1/2  text-white'>
                                <Swiper
                                    className='h-[200px]'
                                    loop={true}
                                    spaceBetween={50}
                                    slidesPerView={1}

                                    autoplay={{
                                        delay: 2000,
                                        disableOnInteraction: false,
                                    }}

                                    speed={1000}
                                    direction={'vertical'}
                                    pagination={{
                                        clickable: true,
                                    }}
                                    modules={[Autoplay, Navigation]}
                                >

                                    <SwiperSlide>
                                        <div className='flex flex-col gap-5'>
                                            <h1 className='text-3xl font-bold text-primary'>Cosmetics</h1>
                                            <p>Transform your store with our next generation AR solution and indulge your customers in experiencing a virtual beauty playground where they can experiment with a vast array of makeup products, effortlessly find their perfect look, and bring up their makeup dreams to life, all from the comfort of their own screen. </p>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div className='flex flex-col gap-5'>
                                            <h1 className='text-3xl font-bold text-primary'>Jewellery</h1>
                                            <p>Experience of a virtual store where your customers discover the perfect match for their style and occasions, whether it's sparkling diamonds, timeless classics, or unique statement pieces. Let them say goodbye to uncertainty and hello to the future of jewellery shopping.</p>
                                        </div>
                                    </SwiperSlide>
                                </Swiper>

                                <div className='flex gap-5 py-10'>
                                    <Button className={'uppercase'} rounded={true} variant={'primary'}>get in touch</Button>
                                    {/* <Button onClick={() => { setModalState(true); setModalType('jwellery') }} className={'uppercase'} rounded={true} variant={'secondary'}>try demo</Button> */}
                                    <Button onClick={TryDemoHandling} className={'lowercase'} rounded={true} variant={'secondary'}>Try Demo</Button>

                                </div>
                            </div>
                            <div className='w-full  lg:w-1/2 xl:w-1/2  text-white'>
                                <Swiper
                                    loop={true}
                                    effect={"coverflow"}
                                    slidesPerView={3}
                                    coverflowEffect={{
                                        rotate: 0,
                                        stretch: 0,
                                        depth: 300,
                                        modifier: 1,
                                    }}
                                    freeMode={true}
                                    autoplay={{
                                        delay: 2000,
                                        disableOnInteraction: false,
                                    }}
                                    speed={1000}
                                    direction={'horizontal'}
                                    pagination={{
                                        clickable: true,
                                    }}
                                    modules={[EffectCoverflow, Autoplay,]}
                                >

                                    {ImageData.map((list, index) =>

                                        <SwiperSlide key={index}>
                                            <ImageCard img={list.image} />
                                        </SwiperSlide>
                                    )}

                                </Swiper>



                            </div>
                        </div>


                    </div>

                </div>


            </div >

            {/* <JwelleryModel state={ModalState} Handler={setModalState} /> */}

            {/* <ArComponents type={ModalType} state={ModalState} Handler={setModalState} typeHandler={setModalType} /> */}
            <IframeSection state={ariframestate} handler={setiframestate} component={arcomponent} />

        </>
    )
}

export default HeroSection

const ImageCard = (props) => {
    return (
        <>
            <div className='bg-white/20 p-[0.3rem] backdrop-blur-sm  rounded-[10px] w-[200px]  aspect-square'>
                <img className='h-[300px] w-auto rounded-[10px] aspect-square object-cover' src={props.img} alt="" />

            </div>
        </>
    )
}

const IframeSection = ({ state, handler, component }) => {

    var  TypeId= useRef('');
    var ProductId= useRef('');
    var appKey=useRef('');
    var appId=useRef('');
    const [loading, setloading]=useState(false)
    const [url, setUrl] = useState(null);
    const [urlbase, setUrlbase] = useState(null);
    // var locahosetname="";
    // var BaseLink=""
    useEffect(()=>{
        console.log(loading)
    },[loading])
    useEffect(()=>{
        let tempUrl = null;
        let tempUrlbase = null;
      
        switch(component){
        
            case "furniture":
                tempUrl ='https://3-d-preview-next-web.vercel.app/';
                tempUrlbase='https://3-d-preview-next-web.vercel.app/'
                setloading(true)  
                
            break;
    
            case "FurnitureCust":
                tempUrl ='https://3-d-customize.vercel.app/';
                tempUrlbase='https://3-d-customize.vercel.app/'
                setloading(true)  
            break;
    
            case  "jwellery":
                TypeId=1
                ProductId=''
                appKey='fa80ff07f89da155'
                appId='1'
               
                // component+'?TypeId='+TypeId+'&ProductId='+ProductId+'&AppKey='+appKey+'&AppId='+appId
                // console.log(component)
                
                tempUrl ='https://product.hashlays.com/product/'+component+'?TypeId='+TypeId+'&ProductId='+ProductId+'&AppKey='+appKey+'&AppId='+appId+'&lat='+lat+'&lang='+lang;
                tempUrlbase='https://product.hashlays.com/'
                setloading(true)  
            break;
    
            case "hands":
                TypeId=3
                ProductId=''
                appKey='fa80ff07f89da155'
                appId='1'
                // console.log(component)
                
                tempUrl ='https://product.hashlays.com/product/'+component+'?TypeId='+TypeId+'&ProductId='+ProductId+'&AppKey='+appKey+'&AppId='+appId+'&lat='+lat+'&lang='+lang;
                tempUrlbase='https://product.hashlays.com/'
                setloading(true)  
            break;
            case "cosmatics":
      
                TypeId=2
                ProductId=''
                appKey='fa80ff07f89da155'
                appId='1'
                tempUrl ='https://product.hashlays.com/product/'+component+'?TypeId='+TypeId+'&ProductId='+ProductId+'&AppKey='+appKey+'&AppId='+appId+'&lat='+lat+'&lang='+lang;
                tempUrlbase='https://product.hashlays.com'
                setloading(true)  
            break;
               case "faceais":
                tempUrl ='https://product.hashlays.com/product/'+component+"?TypeId=4&ProductId=&AppKey=fa80ff07f89da155&AppId=1&lat=0&lang=0&name=John&customerId=123456&email=jhon@gmail.com&firstName=John&lastName=pan&city=Anytown&state=California&postalCode=123423&phoneNumber=1234567891&country=USA&AppLogo=https://hashlays.com/assets/svg/logo.svg";
                tempUrlbase='https://product.hashlays.com'
                setloading(true)  
            break;

            default:
                break;
        
        }
        setUrl(tempUrl);
        setUrlbase(tempUrlbase);






          
    },[component])
    
   
    const handleIframeLoad = () => {
        setloading(false)
       
        // console.log('Iframe has loaded');
      };


    return (
        <>
            {state ?
                <iframe id='mirax-ar-iframe' allow={` camera ${component!=='furniture' ? urlbase:'' }; microphone ${component!=='furniture' ? urlbase:'' }; `}  title='Augment Hashlays' className={` fixed inset-0 z-50 w-full h-full rounded-2xl select-none`} src={state ? url : ''} frameBorder="0" onLoad={handleIframeLoad}></iframe>
                // <iframe id='mirax-ar-iframe' allow='camera https://test-ar-jet.vercel.app; microphone $https://test-ar-jet.vercel.app;'title='Augment Mirax' className={` fixed inset-0 z-50 w-full h-full rounded-2xl select-none`} src={state ? url : ''} frameborder="0"></iframe>
                :
                <>
                </>

            }
             {
                
               ( loading && (
                <LoadingComponent loading={loading}></LoadingComponent>
                        //     <div className="fixed inset-0 z-50 flex flex-col items-center justify-center bg-white/20 backdrop-blur-sm">
                        //     <div className="animate-bounce">
                        //       <img src="./logo512.png" alt="Loading..." className="h-10 w-10" />
                        //     </div>
                        //     <p className='text-white text-xl p-2 '>Loading... </p>
                        //   </div>
                        )
                    )
            
        }
            
            
           
        </>

    )
}



// function IoIosCloseCircle(props) {
//     return <svg stroke="#ffffff" fill="#727272" strokeWidth={0} viewBox="0 0 512 512" height="2em" width="2em" {...props}><path d="M256 48C141.1 48 48 141.1 48 256s93.1 208 208 208 208-93.1 208-208S370.9 48 256 48zm52.7 283.3L256 278.6l-52.7 52.7c-6.2 6.2-16.4 6.2-22.6 0-3.1-3.1-4.7-7.2-4.7-11.3 0-4.1 1.6-8.2 4.7-11.3l52.7-52.7-52.7-52.7c-3.1-3.1-4.7-7.2-4.7-11.3 0-4.1 1.6-8.2 4.7-11.3 6.2-6.2 16.4-6.2 22.6 0l52.7 52.7 52.7-52.7c6.2-6.2 16.4-6.2 22.6 0 6.2 6.2 6.2 16.4 0 22.6L278.6 256l52.7 52.7c6.2 6.2 6.2 16.4 0 22.6-6.2 6.3-16.4 6.3-22.6 0z" /></svg>;
// }
