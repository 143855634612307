import React from 'react'

function Whychooseus() {

    const CardData = [
        {
            id: 1,
            title: 'Modern Technology',
            content: 'Lorem ipsum dolor sit amet consecte adipiscing elit.',
            image: '/assets/svg/setting.svg',

        },
        {
            id: 2,
            title: 'Scalability',
            content: 'Lorem ipsum dolor sit amet consecte adipiscing elit.',
            image: '/assets/svg/scalability.svg',

        },
        {
            id: 3,
            title: 'Versatile Applications',
            content: 'Lorem ipsum dolor sit amet consecte adipiscing elit.',
            image: '/assets/svg/playicon.svg',

        },
        {
            id: 4,
            title: 'Cost Effective',
            content: 'Lorem ipsum dolor sit amet consecte adipiscing elit.',
            image: '/assets/svg/costicon.svg',

        },
    ]

    return (
        <>
            <div className='bg-black brand-container relative'>
                <div className='animate-movingLeft absolute bottom-20 delay-700 right-3 w-[50%] md:w-[40%]  lg:w-[35%] xl:w-[35%] z-0 h-auto rounded-full aspect-square opacity-50 filter blur-3xl bg-gradient-to-b from-[#FF613F] to-[#EB00FF]'></div>

                <div className='h-[40rem] md:h-[35rem] lg:h-[24rem] xl:h-[24rem] w-full bg-[#7A7A8A]/20 border border-white/20  backdrop-blur-sm rounded-[10px] flex flex-col md:flex-row lg:flex-row xl:flex-row justify-between gap-5  items-center p-5'>

                    <div className='h-4/6 w-full md:w-2/6 lg:w-2/6 xl:w-2/6 bg-white/20 border border-white/40 rounded-[10px] relative overflow-hidden flex justify-center items-center'>
                        <img className='absolute top-5 left-0 filter blur-lg' src="assets/images/logo-letter.png" alt="" />
                        <p className='text-white text-xl font-bold text-center'>Why choose MIRAX?</p>
                    </div>

                    <div className='grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 gap-5'>
                        {CardData.map((item, index) =>

                            <Card key={index} title={item.title} content={item.content} img={item.image} />
                        )}

                    </div>



                </div>
            </div>
        </>
    )
}

export default Whychooseus

const Card = (props) => {
    return (
        <>
            <div className='flex items-start gap-5'>
                <img src={props.img} alt="" />
                <div className='flex flex-col gap-3'>
                    <p className='text-white text-xl font-bold'>{props.title}</p>
                    <p className='text-white/40'>{props.content}</p>
                </div>
            </div>
        </>
    )
}