import React, { useState, createContext } from "react";

export const Appcontext = createContext(null);

export const ContextProvider = ({ children }) => {

    const [navbarhide, setNavbarhide] = useState(false)
    const [armodelState, setarmodelState] = useState(false)
    const [ariframestate, setariframestate] = useState(false)
    const [contactModalstate, setcontactModalstate] = useState(false)

    const [arcomponent, setarcomponent] = useState('')



    const value ={
        navbarhide,
        setNavbarhide,
        armodelState,
        setarmodelState,
        ariframestate,
        setariframestate,
        arcomponent,
        setarcomponent,
        contactModalstate,
        setcontactModalstate,

    }

    return(
        <Appcontext.Provider value={value}>{children}</Appcontext.Provider>
    );

};

export default ContextProvider