import React, { useRef, useState } from 'react'
import { Swiper, SwiperSlide } from "swiper/react";
import {  Autoplay } from 'swiper/modules';

import "swiper/css";


function Testimonial() {
    const [activeslideIndex, setactiveslideIndex] = useState(0)
    
    const swiperRef = useRef(null);



    const TestiData =[
        {
            id:1,
            title:'Ana de Armas',
            subtitle:'subtitle',
            content:'Lorem ipsum dolor sit amet consectetur adipiscing elit Ut et massa mi. Aliquam in hendrerit urna. Pellentesque sit amet sapien fringilla, mattis ligula consectetur, ultrices mauris. Maecenas vitae mattis tellus. Nullam quis imperdiet augue. Vestibulum auctor ornare leo, non suscipit magna interdu pellentesque nibh nibh,',
            img:'https://images.unsplash.com/photo-1438761681033-6461ffad8d80?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2940&q=80'
        },
        {
            id:2,
            title:'Armas',
            subtitle:'subtitle',
            content:'Lorem ipsum dolor sit amet consectetur adipiscing elit Ut et massa mi. Aliquam in hendrerit urna. Pellentesque sit amet sapien fringilla, mattis ligula consectetur, ultrices mauris. Maecenas vitae mattis tellus. Nullam quis imperdiet augue. Vestibulum auctor ornare leo, non suscipit magna interdu pellentesque nibh nibh,',
            img:'https://plus.unsplash.com/premium_photo-1664304878313-90217946a9f3?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1277&q=80'
        },
        {
            id:3,
            title:'Ana de',
            subtitle:'subtitle',
            content:'Lorem ipsum dolor sit amet consectetur adipiscing elit Ut et massa mi. Aliquam in hendrerit urna. Pellentesque sit amet sapien fringilla, mattis ligula consectetur, ultrices mauris. Maecenas vitae mattis tellus. Nullam quis imperdiet augue. Vestibulum auctor ornare leo, non suscipit magna interdu pellentesque nibh nibh,',
            img:'https://images.unsplash.com/photo-1439402702863-6434b61e6392?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1740&q=80'
        },
        {
            id:4,
            title:'Ana',
            subtitle:'subtitle',
            content:'Lorem ipsum dolor sit amet consectetur adipiscing elit Ut et massa mi. Aliquam in hendrerit urna. Pellentesque sit amet sapien fringilla, mattis ligula consectetur, ultrices mauris. Maecenas vitae mattis tellus. Nullam quis imperdiet augue. Vestibulum auctor ornare leo, non suscipit magna interdu pellentesque nibh nibh,',
            img:'https://plus.unsplash.com/premium_photo-1664304884312-d7eb299c3b96?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1373&q=80'
        },
        {
            id:5,
            title:'anitha',
            subtitle:'subtitle',
            content:'Lorem ipsum dolor sit amet consectetur adipiscing elit Ut et massa mi. Aliquam in hendrerit urna. Pellentesque sit amet sapien fringilla, mattis ligula consectetur, ultrices mauris. Maecenas vitae mattis tellus. Nullam quis imperdiet augue. Vestibulum auctor ornare leo, non suscipit magna interdu pellentesque nibh nibh,',
            img:'https://images.unsplash.com/photo-1606841216799-94d1952e4da7?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1894&q=80'
        },
    ]

    return (
        <div>
            <div className=' brand-container bg-[#0D0F1F] py-20'>
                <div className='flex flex-col gap-5 justify-center py-5'>
                    <h1 className='text-white uppercase text-center font-earth-orbit-regular text-4xl'>What Our CUSTOMER’S <span className='text-[#38C558]'>SAY</span> </h1>
                </div>

                <div className='flex flex-col md:flex-row lg:flex-row xl:flex-row items-center'>
                    <div className='w-full md:w-1/2 lg:w-1/2 xl:w-1/2'>
                    <Swiper ref={swiperRef} className='' 
                    loop={true}
                    spaceBetween={50}
                    slidesPerView={1}

                    autoplay={{
                        delay: 3000,
                        disableOnInteraction: true,
                    }}

                    speed={500}
                    onSlideChange={(swiper) => setactiveslideIndex(swiper.activeIndex)}
                    direction={'horizontal'}
                    pagination={false}
                    modules={[Autoplay, ]}
                    >
                        {TestiData.map((item, index)=>
                        <SwiperSlide key={index}  className='w-auto'>
                            <img  draggable={false} className='rounded-xl w-full h-[300px] md:h-[500px] lg:h-[500px] xl:h-[500px] object-cover' src={item.img} alt="" />
                        </SwiperSlide>
                        )}

                    </Swiper>
                    </div>
                    <div className='bg-[#545569]/40 backdrop-blur-md z-10 h-fit p-5 rounded-xl -mt-10 md:-ml-10 lg:-ml-10 xl:-ml-10  relative select-none w-full'>
                        <p className='text-white font-bold text-8xl absolute -top-8 right-0'>“</p>
                        {
                            TestiData.map((item, index)=>
                            <div key={index} className={`flex flex-col gap-3 ${index === activeslideIndex ? '':'hidden'}`}>
                            <p className='text-primary text-3xl font-bold'>{item.title}</p>
                            <p className='text-white'>{item.subtitle}</p>
                            <p className='text-white/50 '>{item.content}</p>
                            </div>
                            )

                        }
                    </div>
                </div>

                <div className='flex justify-center pt-10'>
                <CustomPagination swiper={swiperRef.current && swiperRef.current.swiper} />
                </div>
            </div>
        </div>
    )
}

export default Testimonial


const CustomPagination = ({ swiper }) => {
    const totalSlides = swiper ? swiper.slides.length : 0;
    const activeIndex = swiper ? swiper.activeIndex : 0;
  
    const handlePaginationClick = (index) => {
      if (swiper) {
        swiper.slideTo(index);
      }
    };
  
    return (
      <div className="flex gap-2 items-center">
        {Array.from({ length: totalSlides }, (_, index) => (
          <div
            key={index}
            className={`w-3 h-3  rounded-full transition-all duration-300 ${index === activeIndex ? 'bg-primary w-4 h-4' : 'bg-white'}`}
            onClick={() => handlePaginationClick(index)}
          >
            
          </div>
        ))}
      </div>
    );
  };