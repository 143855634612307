import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './style/output.css'
import ContextProvider from './Contextapi/AppContext';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <ContextProvider>
      <App />
    </ContextProvider>
  </React.StrictMode>
);

