import React from 'react'

export default function Section({img, odd, title, content}) {
  return (
    <div className={`flex relative z-10 ${!odd ? 'flex-col md:flex-row lg:flex-row xl:flex-row' : 'flex-col md:flex-row-reverse lg:flex-row-reverse xl:flex-row-reverse' } justify-between items-center`}>
        <img className='h-[400px] w-auto' src={img} alt="" />
        <div className='flex flex-col gap-5 w-full md:w-1/2 lg:w-1/2 xl:w-1/2'>
            <h1 className='text-primary text-3xl font-bold capitalize '>{title}</h1>
            <p>{content}</p>
        </div>
    </div>
  )
}
