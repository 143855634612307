import React from 'react'
import Button from '../../Components/Formcomponents/Button'
// import SigninForm from './Component/signinform'
import { Link } from 'react-router-dom'
import { ScrolltoTop } from '../../Utility'

export default function SignIn() {
    ScrolltoTop();
    document.title = 'Mirax AR - Sign In'

    return (
        <div className='brand-container bg-[#0D0F1F] py-10 pt-[150px] text-white'>
            <div className='bg-[#242B33] p-10 rounded-lg flex flex-col md:flex-row lg:flex-row xl:flex-row justify-between items-start gap-5 '>
                <div className='flex flex-col  items-center gap-10 w-full'>
                    <Link to={'/'}>
                    <img src="/assets/svg/logo.svg" alt="" />
                    </Link>
                    <div className='flex flex-col  items-center gap-5'>
                    <h1 className='text-white font-earth-orbit-regular text-2xl font-bold'>Welcome Back !</h1>
                    <p className='text-center text-lg'>To keep connected with us please
                        login with your personal info</p>
                        <Button className={'!uppercase'} variant={'primary'} rounded={true}>
                            sign in
                        </Button>
                    </div>
                </div>

                {/* <div className='bg-[#181E25] p-10 rounded-xl w-full md:w-1/2 lg:w-1/2 xl:w-1/2'>
                    <SigninForm />
                </div> */}
            </div>
        </div>
    )
}
