const faq_data = [
    {
        title:'What is MIRAX?',
        content:'MIRAX is a SaaS based Augmented Reality(AR) product that lets you create and deploy 3D Models of objects that we buy online.',
    },
    {
        title:'Why should you use Augmented Reality (AR)?',
        content:'AR enhances user experience and let an user visualize how a product will look real time in their home environment.',
    },
    {
        title:'Why should you select MIRAX Ai as your AR solution?',
        content:'MIRAX is a usre value product which has the most user-friendly UI, performance, reliability, scalability and security with the most cost efficient package. This surely makes MIRAX as your best AR solution to go with.'
    },
    {
        title:'Which are the industries currently supported by MIRAX AI?',
        content:'MIRAX currently supports Jewellery and Cosmetics Industry. We are in the beta stage of Furniture and Clothing which can be trailled by contacting our support team.'
    },
    {
        title:'How MIRAX AI helps the E-Commerce industry?',
        content:'MIRAX helps the E-commerce industry in taking advantage of our AI powered AR solutions to create a real life like visualisation of the products that they sell. This helps with your customers in making a purchase decision easier.'
    },
    {
        title:'How can you create 3D assets?',
        content:'One can just upload an image of their product and can create 3D Objects that can be used for AR within MIRAX.'
    },
    {
        title:'How will you be billed?',
        content:'You will be billed based on the monthly API call needs and the number of products that are listed in your account.'
    },
    {
        title:'Will you be able to cancel the MIRAX subscription?',
        content:'You can cancel your MIRAX subscription anytime after the current subscription ends. You can pay for monthly subscription and can choose to not renew it anytime.'
    }
]

module.exports ={
    faq_data
}
