import React from 'react'
import Button from '../../Components/Formcomponents/Button'
import Section from './Components/section'
import BreadCrum from '../../Components/utility/BreadCrum'
import { ScrolltoTop } from '../../Utility'

export default function WebsiteIntegration() {
    ScrolltoTop();
    document.title = 'Hashlays - Website Integration'

    const data = [
        {
            id: 1,
            image: '/assets/images/integration/pic-1.png',
            title: 'Add',
            content: 'Add your digital inventory by uploading files to our backend. Drag, drop and repeat as many times as you want, from wherever you want. Customize every UI element, like typefaces, prompts, and scanning components, using our web AR tool.',
        },
        {
            id: 2,
            image: '/assets/images/integration/pic-2.png',
            title: 'Connect',
            content: 'Bring your catalog to life by integrating our technology into your systems - letting customers try before they buy. Our multi-platform web AR solutions provide for a wider customer base and consistent user experience across operating systems and browsers.',
        },
        {
            id: 3,
            image: '/assets/images/integration/pic-3.png',
            title: 'Add',
            content: 'Add your digital inventory by uploading files to our backend. Drag, drop and repeat as many times as you want, from wherever you want. Customize every UI element, like typefaces, prompts, and scanning components, using our web AR tool.',
        },
    ]

    return (
        <div className='brand-container bg-[#0D0F1F] py-10 pt-[150px] text-white relative'>

            {/* breadcrum */}
            <BreadCrum>
                <p className='font-bold text-xl'>Let's Talk</p>
            </BreadCrum>
            {/* breadcrum */}

            <div className='py-10 text-white  grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 gap-5 items-center'>
                <div className='flex flex-col gap-5 items-start'>
                    <h1 className='font-bold text-3xl'>The simplest method for incorporating augmented reality into the web</h1>
                    <p className='text-sm text-[#ABABAB]'>Experience the simplest method for incorporating augmented reality into your online presence with our cutting-edge technology</p>
                    <Button variant={'primary'} rounded={true} >
                        try demo
                    </Button>
                </div>
                <div className=' relative'>
                    <img className='rounded-xl' src="/assets/images/vi-1.png" alt="" />
                    <div className='absolute inset-0 bg-gradient-to-t from-black to-white/0 to-40%'></div>
                     <img className='absolute bottom-5 right-5 active:opacity-50 cursor-pointer' src="/assets/svg/play-btn.svg" alt="" />
                </div>

            </div>

            <div className='flex flex-col gap-10  relative'>
                <img className='absolute -left-14 z-0 top-0 animate-movingBtoT delay-150' src="/assets/images/round.png" alt="" />
                <img className='absolute -right-14 z-0 bottom-0 animate-movingTtoB delay-150' src="/assets/images/round.png" alt="" />


                {data.map((item, index) =>
                    <Section key={index} img={item.image} odd={item.id % 2 === 0 ? true : false} title={item.title} content={item.content} />
                )}
            </div>

            <div className='  bg-[#0D0F1F] py-10 '>
                <div className='p-5 bg-[#181E25] rounded-lg flex flex-col md:flex-row lg:flex-row xl:flex-row justify-between gap-5 items-center'>
                    <h1 className='text-white font-bold text-4xl bg-gradient-to-r bg-clip-text text-white/0 from-white to-[#47A2EB] to-70%'>Start Your Augmented <br />Reality journey today.</h1>
                    <div className='w-full md:w-1/2 lg:w-1/2 xl:w-1/2 flex flex-col gap-5 items-start'>
                        <p className='text-lg text-[#ABABAB]'>Prepare to embark on your augmented reality adventure; reach out to our sales team for further information.</p>
                        <Button variant={'primary'} rounded={true}>Contact us</Button>
                    </div>

                </div>
            </div>
        </div>
    )
}
