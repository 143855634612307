import { Toaster } from 'react-hot-toast';
import Homepage from "./Pages/Homepage";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Navbar from "./Pages/Layout/Navbar";
import Footer from "./Pages/Layout/Footer";
import PrivacyPolicy from './Pages/Privacy_policy';
import TermsandConditions from './Pages/Terms_and_condition';
import PageNotFound from './Pages/PageNotFound';
import SignIn from './Pages/SignIn';
import WebsiteIntegration from './Pages/Website';
import LetsTalk from './Pages/lets-talk';
import ContactModal from './Components/utility/ContactModal';
import Celbrate from './Pages/Celebrate';
import { useEffect } from 'react';



function App() {
  useEffect(() => {

    document.addEventListener('contextmenu',(e)=>{
      e.preventDefault();
    }
    );
    document.onkeydown = function(e) {
    if(e.keyCode === 123) {
      console.log("123")
      // alert("HA HA HA You Cannot Do This!");
       return false;
    }
    if(e.ctrlKey && e.shiftKey && e.keyCode ==='I'.charCodeAt(0)) {
      console.log("i")
      // alert("HA HA HA You Cannot Do This!");
       return false;
    }
    if(e.ctrlKey && e.shiftKey && e.keyCode === 'C'.charCodeAt(0)) {
      console.log("c")
      // alert("HA HA HA You Cannot Do This!");
       return false;
    }
    if (e.keyCode === 83 && (navigator.platform.match("Mac") ? e.metaKey : e.ctrlKey)) { 
      // alert("HA HA HA You Cannot Do This!");
      e.preventDefault(); 
   return false;
   }
    if(e.ctrlKey && e.shiftKey && e.keyCode === 'J'.charCodeAt(0)) {
      console.log("j")
      // alert("HA HA HA You Cannot Do This!");
       return false;
    }
    if(e.ctrlKey && e.keyCode === 'U'.charCodeAt(0)) {
      console.log("U")
      // alert("HA HA HA You Cannot Do This!");
       return false;
    }
    
    
  }
  if (document.addEventListener) {
      document.addEventListener('contextmenu', function(e) {
        // alert("HA HA HA You Cannot Do This!");
        e.preventDefault();
      }, false);
    } else {
      document.attachEvent('oncontextmenu', function() {
        // alert("HA HA HA You Cannot Do This!");
        window.event.returnValue = false;
      });
    }
  }, [])
  

  document.title = "Hashlays";

  return (
    <>

      <Router>
        <Navbar />
        <Routes>
          <Route path="/" element={<Homepage />} />
          {/* <Route  path="/contact" element={<Contact />} /> */}
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/terms-&-condition" element={<TermsandConditions />} />
          <Route path="/sign-in" element={<SignIn />} />
          <Route path="/website-integration" element={<WebsiteIntegration />} />
          <Route path="/lets-talk" element={<LetsTalk />} />
          <Route path="/leo-celeberation" element={<Celbrate />} />
          <Route path="*" element={<PageNotFound />} />
        </Routes>
        <Footer />
        <Toaster />
        <ContactModal />
      </Router>

    </>
  );
}

export default App;
