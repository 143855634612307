import React from 'react';
import Lottie from 'lottie-react';
import loadingAnimation from '../../Utility/Hashlayslogo.json'; // Replace with your actual file path
// import { ReactComponent as CustomSVG } from './path/to/your/svg-file.svg'; // Replace with your actual file path

const LoadingComponent = ({ loading }) => {
  return (
    loading && (
      <div className="fixed inset-0 z-50 flex flex-col items-center justify-center bg-white/20 backdrop-blur-sm">
        <div className="">
          {/* Lottie Animation */}
          <Lottie animationData={loadingAnimation} loop={true} style={{ height: 120, width: 120 }} />
        </div>
        {/* <p className="text-white text-xl p-2">Loading...</p> */}
        {/* SVG Image */}
        <img className="w-18 animate-pulse" src='../LogoText.svg'/>
        {/* <C className="h-10 w-10 mt-4" /> */}
      </div>
    )
  );
};

export default LoadingComponent;
