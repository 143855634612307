import React from 'react'
import { useForm } from "react-hook-form"

import Button from '../../../Components/Formcomponents/Button'
import { NotificationService } from '../../../Service/NotificationService';
import useFetchData from '../../../CustomHooks/useFetchData';

function LetsTalkForm() {

    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm();

    const { makeRequest, loading } = useFetchData()


    const onSubmit = async (data) => {

        if (loading) return

        try {
            const Response = await makeRequest('mail/send', 'post', data, true);
            console.log(Response);
            if (Response.status) {
                NotificationService(Response.message, "success", "top-right")
                reset()
            }
        } catch (error) {
            console.error(error, 'from form')
        }



    }

  return (
    <form onSubmit={handleSubmit(onSubmit)} >
    <div className='flex flex-col gap-5'>
        <h3 className='font-earth-orbit-regular text-3xl'>First let’s get acquainted</h3>
        <div className='grid grid-cols-1 md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 gap-5'>
            <input type="text" {...register("name", { required: true })} className={`${errors.name ? 'border-[#FF0000] border' : ''} w-full h-[50px] placeholder:capitalize placeholder:text-[#AAAAAA]/50 rounded-[10px] px-3 text-[#AAAAAA] bg-[#242B33] outline-none`} placeholder='First Name' />
            <input type="text" {...register("email", { required: true })} className={`${errors.email ? 'border-[#FF0000] border' : ''} w-full h-[50px] placeholder:capitalize placeholder:text-[#AAAAAA]/50 rounded-[10px] px-3 text-[#AAAAAA] bg-[#242B33] outline-none`} placeholder='Last Name' />

        </div>
        
        <div className='grid grid-cols-1 md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 gap-5'>
            <input type="text" {...register("name", { required: true })} className={`${errors.name ? 'border-[#FF0000] border' : ''} w-full h-[50px] placeholder:capitalize placeholder:text-[#AAAAAA]/50 rounded-[10px] px-3 text-[#AAAAAA] bg-[#242B33] outline-none`} placeholder='Phone Number' />
            <input type="text" {...register("email", { required: true })} className={`${errors.email ? 'border-[#FF0000] border' : ''} w-full h-[50px] placeholder:capitalize placeholder:text-[#AAAAAA]/50 rounded-[10px] px-3 text-[#AAAAAA] bg-[#242B33] outline-none`} placeholder='Email Address' />

        </div>
        <h3 className='font-earth-orbit-regular text-3xl'>First let’s get acquainted</h3>

        <div className='grid grid-cols-1 md:grid-cols-1 lg:grid-cols-1 xl:grid-cols-1 gap-5'>
            <input type="text" {...register("company_name", { required: true })} className={`${errors.company_name ? 'border-[#FF0000] border' : ''} w-full h-[50px] placeholder:capitalize placeholder:text-[#AAAAAA]/50 rounded-[10px] px-3 text-[#AAAAAA] bg-[#242B33] outline-none`} placeholder='What is your Business Name' />
        </div>
        <div className='grid grid-cols-1 md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 gap-5'>
            <input type="text" {...register("name", { required: true })} className={`${errors.name ? 'border-[#FF0000] border' : ''} w-full h-[50px] placeholder:capitalize placeholder:text-[#AAAAAA]/50 rounded-[10px] px-3 text-[#AAAAAA] bg-[#242B33] outline-none`} placeholder='Where are you based ?' />
            <input type="text" {...register("email", { required: true })} className={`${errors.email ? 'border-[#FF0000] border' : ''} w-full h-[50px] placeholder:capitalize placeholder:text-[#AAAAAA]/50 rounded-[10px] px-3 text-[#AAAAAA] bg-[#242B33] outline-none`} placeholder='Your Website' />
        </div>


        <h3 className='font-earth-orbit-regular text-3xl'>What services are you interested in</h3>
        <div className='flex gap-10 flex-wrap py-5'>
            <div className='flex gap-5 items-center'>
                <input type="checkbox" name="" className='scale-150' id="furniture" />
                <label className='text-xl' for="furniture">Furniture AR</label>
            </div>
            <div className='flex gap-5 items-center'>
                <input type="checkbox" name="" className='scale-150' id="jwellery-ar" />
                <label className='text-xl' for="jwellery-ar">Jewellery AR</label>
            </div>
            <div className='flex gap-5 items-center'>
                <input type="checkbox" name="" className='scale-150' id="image-ar" />
                <label className='text-xl' for="image-ar">Image AR</label>
            </div>
            <div className='flex gap-5 items-center'>
                <input type="checkbox" name="" className='scale-150' id="cosmetics-ar" />
                <label className='text-xl' for="cosmetics-ar">Cosmetics AR</label>
            </div>
            <div className='flex gap-5 items-center'>
                <input type="checkbox" name="" className='scale-150' id="multispace-ar" />
                <label className='text-xl' for="multispace-ar">Multispace AR</label>
            </div>
        </div>

        <textarea {...register("message", { required: true })} placeholder='Describe the main purpose and primary goals of your product' rows={8} className={`${errors.message ? 'border-[#FF0000] border' : ''} w-full placeholder:capitalize placeholder:text-[#AAAAAA]/50 rounded-[10px] p-3 text-[#AAAAAA] bg-[#242B33] outline-none`}></textarea>

        <div className='grid grid-cols-1 md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 gap-5'>
            <input type="text" {...register("name", { required: true })} className={`${errors.name ? 'border-[#FF0000] border' : ''} w-full h-[50px] placeholder:capitalize placeholder:text-[#AAAAAA]/50 rounded-[10px] px-3 text-[#AAAAAA] bg-[#242B33] outline-none`} placeholder='What is your approximate budget ?' />
            <input type="text" {...register("email", { required: true })} className={`${errors.email ? 'border-[#FF0000] border' : ''} w-full h-[50px] placeholder:capitalize placeholder:text-[#AAAAAA]/50 rounded-[10px] px-3 text-[#AAAAAA] bg-[#242B33] outline-none`} placeholder='How do you hear about Us ?' />
        </div>

        <div className='flex justify-start'>

            <Button type={'submit'} variant={'primary'} rounded={true} className={`w-fit`}>
                {
                    loading ?
                        <>
                            <img className='w-5 h-auto animate-spin' src="/assets/svg/loader.svg" alt="loading" />
                        </>
                        : 'send it'
                }
            </Button>
        </div>
    </div>
</form>
  )
}

export default LetsTalkForm