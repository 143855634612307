import React, { useContext } from 'react'
import { useForm } from 'react-hook-form'
import useFetchData from '../../CustomHooks/useFetchData'
import Button from '../Formcomponents/Button'
import { NotificationService } from '../../Service/NotificationService'
import { Appcontext } from '../../Contextapi/AppContext'

export default function ContactModal() {

    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm()

    const {contactModalstate, setcontactModalstate} = useContext(Appcontext)

    const { makeRequest, loading } = useFetchData()


    const onSubmit = async (data) => {

        if (loading) return

        try {
            const Response = await makeRequest('mail/send', 'post', data, true);
            console.log(Response);
            if (Response.status) {
                NotificationService(Response.message, "success", "top-right")
                reset()
            }
        } catch (error) {
            console.error(error, 'from form')
        }



    }

    const closeHandler = (e) =>{
        const id = e.target.id;
        if(id === 'contact-modal'){
            setcontactModalstate(false)
        }
    }

    return (
        <div id="contact-modal" onClick={closeHandler} className={` ${contactModalstate ? 'fixed':'hidden'} fixed z-50 bg-primary/20 inset-0 flex justify-center items-center`}>
        <div className='bg-[#181E25] p-5 rounded-[20px] w-full md:w-1/2 lg:w-1/2 xl:w-1/2'>
            <p className='uppercase pb-5 text-white font-bold text-2xl'>leave a <span className='text-[#38C558]'>message</span></p>
            <form onSubmit={handleSubmit(onSubmit)} >
                <div className='flex flex-col gap-5'>
                    <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 gap-5'>
                        <input type="text" {...register("name", { required: true })} className={`${errors.name ? 'border-[#FF0000] border' : ''} w-full h-[50px] placeholder:capitalize placeholder:text-[#AAAAAA]/50 rounded-[10px] px-3 text-[#AAAAAA] bg-[#242B33] outline-none`} placeholder='Name' />
                       
                        <input type="text" {...register("email", { required: true })} className={`${errors.email ? 'border-[#FF0000] border' : ''} w-full h-[50px] placeholder:capitalize placeholder:text-[#AAAAAA]/50 rounded-[10px] px-3 text-[#AAAAAA] bg-[#242B33] outline-none`} placeholder='email' />

                    </div>
                    <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 gap-5'>
                        <input type="text" {...register("company_name", { required: true })} className={`${errors.company_name ? 'border-[#FF0000] border' : ''} w-full h-[50px] placeholder:capitalize placeholder:text-[#AAAAAA]/50 rounded-[10px] px-3 text-[#AAAAAA] bg-[#242B33] outline-none`} placeholder='company name' />
                        <input type="text" {...register("phone_number", { required: true })} className={`${errors.phone_number ? 'border-[#FF0000] border' : ''} w-full h-[50px] placeholder:capitalize placeholder:text-[#AAAAAA]/50 rounded-[10px] px-3 text-[#AAAAAA] bg-[#242B33] outline-none`} placeholder='phone number' />

                    </div>
                    <textarea {...register("message", { required: true })} placeholder='your message' rows={8} className={`${errors.message ? 'border-[#FF0000] border' : ''} w-full placeholder:capitalize placeholder:text-[#AAAAAA]/50 rounded-[10px] p-3 text-[#AAAAAA] bg-[#242B33] outline-none`}>

                    </textarea>
                    <div className='flex justify-center'>

                        <Button type={'submit'} variant={'primary'} rounded={true} className={`w-fit`}>
                            {
                                loading ?
                                    <>
                                        <img className='w-5 h-auto animate-spin' src="/assets/svg/loader.svg" alt="loading" />
                                    </>
                                    : 'send message'
                            }
                        </Button>
                    </div>
                </div>
            </form>
        </div>
        </div>
    )
}
