import axios from 'axios';
import { REACT_APP_API_URL } from '../Utility/constant';
import { LocalStorageService } from './LocalStorageService';

class ApiService {
    static axiosInstance;

    /**
     * Initialize for Axios Instance for interceptor
     */
    static initialize() {
        // Create a new instance of Axios with default configurations
        this.axiosInstance = axios.create({
            baseURL: REACT_APP_API_URL, // Set your API base URL here
            timeout: 10000, // Set request timeout in milliseconds
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Headers': '*',
                // Add any common headers you need for your requests
                // For example, Authorization header for authentication
            },
        });

        // Request interceptor: Runs before each request is sent
        this.axiosInstance.interceptors.request.use(
            (config) => {
                // You can modify the request config here, e.g., add headers, tokens, etc.
                const accessToken = LocalStorageService.getItem('access_token');
                if (accessToken) {
                    config.headers['Authorization'] = `Bearer ${accessToken}`;
                }
                return config;
            },
            (error) => {
                // Handle request error here
                return Promise.reject(error);
            }
        );

        // Response interceptor: Runs after each response is received
        this.axiosInstance.interceptors.response.use(
            (response) => {
                // You can modify the response data here before it's returned to the caller
                return response.data;
            },
            (error) => {
                // Handle response error here
                return Promise.reject(error);
            }
        );
    }

    /**
     * AXIOS GET METHOD
     * @param {*} endpoint 
     * @param {*} params 
     * @returns 
     */
    static async get(endpoint, params = {}) {
        try {
            const response = await this.axiosInstance.get(endpoint, { params });
            return response;
        } catch (error) {
            throw error;
        }
    }

    /**
     * AXIOS POST METHOD
     * @param {*} endpoint 
     * @param {*} data 
     * @returns 
     */
    static async post(endpoint, data = {}) {
        try {
            const response = await this.axiosInstance.post(endpoint, data);
            return response;
        } catch (error) {
            throw error;
        }
    }

    // Add other HTTP methods (PUT, DELETE, etc.) as needed...

}

// Initialize the Axios instance when the application starts
ApiService.initialize();

export default ApiService;
