import React from 'react'

function HowitWorks() {
  return (
    <div className='brand-container bg-[#0D0F1F] py-16 flex flex-col gap-20'>
      <div className='flex flex-col items-center gap-5'>

        <p className='uppercase  text-white  font-bold  font-earth-orbit-regular text-4xl'> How it <span className='text-[#38C558]'>works</span> </p>
      </div>
      <div className='flex flex-col md:flex-row lg:flex-row xl:flex-row gap-20 justify-between items-center md:items-end lg:items-end xl:items-end'>
        <div className='relative w-fit'>
          <img className='absolute right-0 top-0 animate-movingRight' src="/assets/images/round.png" alt="" />
          <img className='w-[400px] h-auto rounded-[20px] relative z-20 shadow-[19px_19px_49px_-16px_#000000]' src="/assets/images/sect-video.png" alt="" />
          <img className='w-[400px] h-auto rounded-[20px] absolute -top-5 z-10 shadow-[19px_19px_49px_-16px_#000000]' src="/assets/images/sect-video.png" alt="" />
          <img className='w-[400px] h-auto rounded-[20px] absolute -top-10 z-0 shadow-[19px_19px_49px_-16px_#000000]' src="/assets/images/sect-video.png" alt="" />
          <div className='absolute z-20 inset-0 bg-gradient-to-t from-black/70 to-white/0 p-2 flex items-end justify-end'>
            <img className='active:opacity-50' src="/assets/svg/pause.svg" alt="" />
          </div>
        </div>
        <div className='md:w-1/2 lg:w-1/2 xl:w-1/2 flex flex-col gap-5'>
          <h3 className='text-[#569EF6] text-3xl font-bold'>Cosmetics AR</h3>
          <h4 className='text-2xl font-bold text-white'>Heading</h4>
          <p className='text-[#ABABAB] text-lg'>Transform your store with our next generation AR solution and indulge your customers in experiencing a virtual beauty playground where they can experiment with a vast array of makeup products, effortlessly find their perfect look, and bring up their makeup dreams to life, all from the comfort of their own screen.</p>
        </div>
      </div>

      <div className='flex flex-col md:flex-row-reverse lg:flex-row-reverse xl:flex-row-reverse gap-20 justify-between items-center md:items-end lg:items-end xl:items-end'>
        <div className='relative w-fit'>
          <img className='absolute right-0 top-0 animate-movingLeft delay-150' src="/assets/images/round.png" alt="" />

          <img className='w-[400px] h-auto rounded-[20px] relative z-20 shadow-[19px_19px_49px_-16px_#000000]' src="/assets/images/sect-video.png" alt="" />
          <img className='w-[400px] h-auto rounded-[20px] absolute -top-5 z-10 shadow-[19px_19px_49px_-16px_#000000]' src="/assets/images/sect-video.png" alt="" />
          <img className='w-[400px] h-auto rounded-[20px] absolute -top-10 z-0 shadow-[19px_19px_49px_-16px_#000000]' src="/assets/images/sect-video.png" alt="" />
          <div className='absolute z-20 inset-0 bg-gradient-to-t from-black/70 to-white/0 p-2 flex items-end justify-end'>
            <img className='active:opacity-50' src="/assets/svg/pause.svg" alt="" />
          </div>
        </div>
        <div className='md:w-1/2 lg:w-1/2 xl:w-1/2 flex flex-col gap-5'>
          <h3 className='text-[#569EF6] text-3xl font-bold'>Jewellery AR</h3>
          <h4 className='text-2xl font-bold text-white'>Heading</h4>
          <p className='text-[#ABABAB] text-lg'>Experience of a virtual store where your customers discover the perfect match for their style and occasions, whether it's sparkling diamonds, timeless classics, or unique statement pieces. Let them say goodbye to uncertainty and hello to the future of jewellery shopping.</p>
        </div>
      </div>


      <div className='flex flex-col md:flex-row lg:flex-row xl:flex-row gap-20 justify-between items-center md:items-end lg:items-end xl:items-end'>
        <div className='relative w-fit'>
          <img className='absolute right-0 top-0 animate-movingRight' src="/assets/images/round.png" alt="" />
          <img className='w-[400px] h-auto rounded-[20px] relative z-20 shadow-[19px_19px_49px_-16px_#000000]' src="/assets/images/sect-video.png" alt="" />
          <img className='w-[400px] h-auto rounded-[20px] absolute -top-5 z-10 shadow-[19px_19px_49px_-16px_#000000]' src="/assets/images/sect-video.png" alt="" />
          <img className='w-[400px] h-auto rounded-[20px] absolute -top-10 z-0 shadow-[19px_19px_49px_-16px_#000000]' src="/assets/images/sect-video.png" alt="" />
          <div className='absolute z-20 inset-0 bg-gradient-to-t from-black/70 to-white/0 p-2 flex items-end justify-end'>
            <img className='active:opacity-50' src="/assets/svg/pause.svg" alt="" />
          </div>
        </div>
        <div className='md:w-1/2 lg:w-1/2 xl:w-1/2 flex flex-col gap-5'>
          <h3 className='text-[#569EF6] text-3xl font-bold'>Furniture AR</h3>
          <h4 className='text-2xl font-bold text-white'>Heading</h4>
          <p className='text-[#ABABAB] text-lg'>Transform your store with our next generation AR solution and indulge your customers in experiencing a virtual beauty playground where they can experiment with a vast array of makeup products, effortlessly find their perfect look, and bring up their makeup dreams to life, all from the comfort of their own screen.</p>
        </div>
      </div>
    </div>
  )
}

export default HowitWorks